export default defineNuxtRouteMiddleware(async (_) => {
  const {
    public: { siteRegion }, // siteRegion 代表 site
  } = useRuntimeConfig()
  if (siteRegion === 'sosovalue') {
    throw createError({
      statusCode: 403,
      message: 'Permission denied',
    })
  }
})
